var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ek-dialog',{ref:"dialog",attrs:{"btnText":"مستخدم جديد","title":'اضافة مستخدم'},on:{"ok":_vm.submitForm,"close":_vm.resetForm},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validationObserver',{ref:"dashUserForm"},[_c('ek-input-text',{attrs:{"name":"الاسم","label":"الاسم","rules":[
                        { type: 'required', message: 'اسم المستخدم مطلوب' }
                    ]},model:{value:(_vm.dtoDetailsDashUser.name),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "name", $$v)},expression:"dtoDetailsDashUser.name"}}),_c('ek-input-text',{attrs:{"rules":[
                        { type: 'required', message: 'الاسم مطلوب' },
                        {
                            type: 'email',
                            message: 'يرجى ادخال بريد الكتروني صحيح'
                        }
                    ],"name":"البريد الالكتروني","label":"البريد الالكتروني"},model:{value:(_vm.dtoDetailsDashUser.email),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "email", $$v)},expression:"dtoDetailsDashUser.email"}}),_c('ek-input-text',{attrs:{"rules":[
                        {
                            type: 'min:4',
                            message:
                                'كلمة المرور يجب ان تكون اطول من 4 محارف'
                        }
                    ],"name":"كلمة السر","label":"كلمة السر"},model:{value:(_vm.dtoDetailsDashUser.password),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "password", $$v)},expression:"dtoDetailsDashUser.password"}}),_c('ek-input-text',{attrs:{"rules":[
                        { type: 'required', message: 'رقم مطلوب' },
                        { type: 'mobile', message: 'رقم الهاتف غير صالح' }
                    ],"name":"رقم الهاتف","label":"رقم الهاتف"},model:{value:(_vm.dtoDetailsDashUser.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "phoneNumber", $$v)},expression:"dtoDetailsDashUser.phoneNumber"}}),_c('ek-date-picker',{attrs:{"rules":[
                        { type: 'required', message: 'تاريخ الميلاد مطلوب' }
                    ],"name":"تاريخ الميلاد","label":"تاريخ الميلاد"},model:{value:(_vm.dtoDetailsDashUser.birthDate),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "birthDate", $$v)},expression:"dtoDetailsDashUser.birthDate"}}),_c('ek-input-select',{attrs:{"options":_vm.dashUserRole,"name":" الصلاحيات","label":" الصلاحيات"},model:{value:(_vm.dtoDetailsDashUser.role),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "role", $$v)},expression:"dtoDetailsDashUser.role"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }