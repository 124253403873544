<template>
    <div>
        <ek-dialog
            btnText="مستخدم جديد"
            ref="dialog"
            :title="'اضافة مستخدم'"
            @ok="submitForm"
            @close="resetForm"
        >
            <template #body>
                <validationObserver ref="dashUserForm">
                    <ek-input-text
                        v-model="dtoDetailsDashUser.name"
                        name="الاسم"
                        label="الاسم"
                        :rules="[
                            { type: 'required', message: 'اسم المستخدم مطلوب' }
                        ]"
                    ></ek-input-text>
                    <ek-input-text
                        v-model="dtoDetailsDashUser.email"
                        :rules="[
                            { type: 'required', message: 'الاسم مطلوب' },
                            {
                                type: 'email',
                                message: 'يرجى ادخال بريد الكتروني صحيح'
                            }
                        ]"
                        name="البريد الالكتروني"
                        label="البريد الالكتروني"
                    ></ek-input-text>
                    <ek-input-text
                        v-model="dtoDetailsDashUser.password"
                        :rules="[
                            {
                                type: 'min:4',
                                message:
                                    'كلمة المرور يجب ان تكون اطول من 4 محارف'
                            }
                        ]"
                        name="كلمة السر"
                        label="كلمة السر"
                    ></ek-input-text>
                    <ek-input-text
                        v-model="dtoDetailsDashUser.phoneNumber"
                        :rules="[
                            { type: 'required', message: 'رقم مطلوب' },
                            { type: 'mobile', message: 'رقم الهاتف غير صالح' }
                        ]"
                        name="رقم الهاتف"
                        label="رقم الهاتف"
                    ></ek-input-text>
                    <ek-date-picker
                        v-model="dtoDetailsDashUser.birthDate"
                        :rules="[
                            { type: 'required', message: 'تاريخ الميلاد مطلوب' }
                        ]"
                        name="تاريخ الميلاد"
                        label="تاريخ الميلاد"
                    ></ek-date-picker>
                    <ek-input-select
                        :options="dashUserRole"
                        v-model="dtoDetailsDashUser.role"
                        name=" الصلاحيات"
                        label=" الصلاحيات"
                    ></ek-input-select>
                </validationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            dashUserRole: state => state.dashboardUsers.dashUserRole,
            dtoDetailsDashUser: state => state.dashboardUsers.dtoDetailsDashUser
        })
    },
    methods: {
        ...mapActions(["addDashUser"]),
        submitForm() {
            this.$refs.dashUserForm.validate().then(suc => {
                if (suc) {
                    this.addDashUser(this.dtoDetailsDashUser);
                    this.$refs.dialog.close();
                }
            });
        },
        resetForm() {
            this.$refs.dashUserForm.reset();
            this.$store.commit("Reset_User_Dto");
        }
    }
};
</script>

<style></style>
